.login-popup {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    visibility: visible;
    opacity: 1;
    transition: all 1s ease;
  }
  
  
  .box {
    background-color: #ffffff;
    width: 750px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    z-index: 2000;
  }
  
  .img-area {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    overflow: hidden;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
  }
  
  .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url("../../assets/popupimg/mithilastack.svg"); */
    background-size: cover;
    background-position: center;
    animation: zoomInOut 7s linear infinite;
    z-index: -1;
    image-rendering: auto;
  }
  
  
  @keyframes zoomInOut {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .form {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 40px 30px;
    background-color: rgb(247 247 247 /1);
    border-radius: 0 10px 10px 0;
  }
  
  .form h1 {
    color: #000000;
    font-size: 30px;
    /* margin: 0 0 20px; */
  }
  
  .form-control {
    padding-left: 5px;
    height: 35px;
    margin-top: 20px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 15px;
    color: #000000;
    background-color: rgb(247 247 247 /1);
  }
  
  .form-control:focus {
    outline: none;
    border: 2px solid #e2dcdc;
    border-radius: 5px;
  }
  
  .form label {
    font-size: 15px;
    color: #555555;
  }
  
  .btn {
    width: 100%;
    background-color: #e91e63;
    margin-top: 20px;
    height: 45px;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }
  
  .close {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 30px;
    cursor: pointer;
    color:#000000
  }
  textarea {
    background-color: rgb(247 247 247 /1);
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    margin-top:20px
  }
  
  /* for checbox and crossbox */
  .error {
    color: red;
    font-size: 90%;
  }
  input[type="text"] {
    outline: none;
  }
  
  input[type="submit"] {
    font-size: 110%;
    font-weight: 100;
    background: #ef6e58;
    border-color: #ef6e58;
    box-shadow: 0 3px 0 #bd432e;
    color: #fff;
    margin-top: 10px;
    cursor: pointer;
  }
  input[type="submit"]:hover {
    background: #bd432e;
    border-color: #bd432e;
  }
  
  .input-1 {
    background-image: url("../../assets/popupimg/check.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    border: 1px solid #01cc40;
  }
  .input-2 {
    background-image: url("../../assets/popupimg/cancel.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    border: 1px solid red;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff; /* Change this color to your desired loading spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* responsive */
  @media (max-width: 767px) {
    .box {
      width: calc(100% - 30px);
    }
    .img-area {
      display: none;
    }
     .form {
      flex: 0 0 100%;
      max-width: 100%;
      border-radius:10px
    }
    .form h1 {
      font-size: 20px;
      /* margin: 0 0 20px; */
    }
  }
  
  @media (max-width: 275px) {
    .form-control {
      margin-top: 15px;
    }
    textarea{
      margin-top:15px;
    }
  }