/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/* Copyright 2023 MithilaStack */


/* CUSTOM PROPERTY */
:root {
  --eerie-black-1: hsl(0, 0%, 9%);
  --eerie-black-2: hsl(180, 3%, 7%);
  --gray-x-11: hsl(0, 0%, 73%);
  --kappel: hsl(170, 75%, 41%);
  --white: hsl(0, 0%, 100%);
  --ff-league_spartan: 'League Spartan', sans-serif;
  --fs-3: 2.3rem;
  --fs-5: 1.5rem;
  --fw-500: 500;
  --fw-600: 600;
  --transition-1: 0.25s ease;
  --tablet-breakpoint: 768px;
  --desktop-breakpoint: 1024px;
  --radius-5: 5px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.footer {
  background-repeat: no-repeat;
  background-color: var(--eerie-black-2);
  color: var(--gray-x-11);
  font-size: var(--fs-5);
  padding: 20px 0;
}

.footer-top {
  display: grid;
  gap: 30px;
}

.footer-brand-text {
  margin-block: 20px;
  font-size: 1rem;
  align-items: center;
  text-align: center;
}

.footer-brand .wrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5; 
}

.footer-brand .wrapper .span {
  font-weight: var(--fw-500);
}

.footer-link {
  transition: var(--transition-1);
  padding: 10px;
  font-size: 1rem;
}

.footer-link:is(:hover, :focus) {
  color: var(--kappel);
}

.footer-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 160px; /* Default padding for non-mobile screens */

  /* Media query for screens smaller than 768px (typical mobile screens) */
  @media (max-width: 767px) {
    padding-top: 0; /* Adjusted padding for mobile screens */
  }
}


.footer-list-title {
  color: var(--white);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-3);
  font-weight: var(--fw-600);
  margin-block-end: 10px;
}

.footer-list-text {
  text-align: center;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 20px 10px;
}

.input-field {
  background-color: var(--white);
  padding: 12px;
  border-radius: var(--radius-5);
  margin-block-end: 20px;
  width: 100%;
}

.btn {
  min-width: 100%;
  justify-content: center;
  background-color: var(--kappel);
  color: var(--white);
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color var(--transition-1);
}

.btn .span {
  /* Your styles for the <span> element here */
  font-size: 0.5em; /* Example font size adjustment */
}

.btn:hover {
  background-color: lighten(var(--kappel), 10%);
}

.social-list {
  display: flex;
  gap: 15px;
}

.social-link {
  font-size: 20px;
}

.footer-bottom {
  border-block-start: 1px solid var(--eerie-black-1);
  padding-block: 30px;
}

.copyright {
  text-align: center;
}

.copyright-link {
  color: var(--kappel);
  display: inline-block;
}

/* Media queries for different screen sizes */
@media only screen and (max-width: var(--tablet-breakpoint)) {
  .footer-brand-text {
    font-size: 1rem;
  }

  .footer-link {
    font-size: 0.8rem;
  }

  .footer-list {
    text-align: center;
  }

  .newsletter-form {
    text-align: center;
  }

  .btn {
    min-width: auto;
  }
}

@media only screen and (max-width: var(--desktop-breakpoint)) {
  /* Additional styles for larger screens if needed */
}
